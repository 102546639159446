/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { API_GET, API_POST } from "../services/api.service";
import { cacheUtil, getToken, setCookie, setToken } from "../services/Common";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import Footer from "components/Footer/Footer.jsx";

import routes from "routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-dark.png";


let userInfo = {};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      user: {},
      expired: false,
      expireon: null,
      infoLinkData: {}
    };
  }
  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  activeRoute(routeName) {
    return window.location.pathname.indexOf(routeName) > -1 ? true : false;
  }


  async fetchInfoLink(name) {
    let infomap = {};
    try {
      const infoLinkResponse = await API_POST("action/getdata", {
        root: "Information_link",
        con: {}
      });

      console.log("infoLinkResponse", infoLinkResponse);

      if (infoLinkResponse && infoLinkResponse.data && infoLinkResponse.data.length) {
        const infoLinkData = infoLinkResponse.data;
        console.log("infoLinkData", infoLinkData);

        // Convert the infoLinkData array to infomap object
        infoLinkData.forEach((v) => {
          infomap[v.name] = v;
        });

        // Store the infomap data in local storage
        localStorage.setItem('infoLinkData', JSON.stringify(infomap));
      } else {
        console.error("Failed to fetch infoLink - Invalid response structure");
      }
    } catch (error) {
      console.error("Error fetching infoLink:", error);
    }
    return infomap;
  }

  async GetAllMembersByUserId(userid) {
    let userInfo = await API_GET(`register/GetAllMembersByUserId?id=${userid}`);
    if (userInfo.success) {
      let teamList = {};
      userInfo.data.teams_membership.map(v => teamList[v.email] = { first_name: v.first_name, phone: v.phone, role: v.role, reporter_id: v.reporter_id });
      return teamList;
    }
  }

  async componentDidMount() {
    if (getToken()) {
    } else {
      const urlParams = new URLSearchParams(this.props.location.search);
      const token = urlParams.get("token");
      if (token) {
        //localStorage.setItem("token", token);
        setToken(token);
      } else {
        const { history } = this.props;
        return history.push("/auth/login-page");
      }
    }

    for (let index = 0; index < routes.length; index++) {
      const prop = routes[index];
      if (this.activeRoute(prop.layout + prop.path)) {
        document.title = prop.name;
      }
    }

    if (navigator.platform.indexOf("Win") > -1) {
    }
    //window.addEventListener("resize", this.resizeFunction);
    let getSessionRequest;
    if (cacheUtil.get("get-session")) {
      getSessionRequest = cacheUtil.get("get-session");
    }
    else {
      getSessionRequest = await API_GET(`get-session`);
      
    }

    let configuration;
    if (cacheUtil.get("Configuration")) {
      configuration = cacheUtil.get("Configuration");
    }
    else {
      configuration = await API_POST("action/getdata", {
        root: "Configuration",
        con: { admin: getSessionRequest && getSessionRequest.userInfo && getSessionRequest.userInfo.admin },
        cols: {},
      });
    }

    
    let confSettings = {};
    if (configuration && configuration.data && configuration.data.length) {
      cacheUtil.set("Configuration", configuration, 60*60*1000);
      configuration.data.map((v) => {
        confSettings = { ...confSettings, ...v };
      })
      confSettings = { ...confSettings, version: "1.8.10" };
    }

    if (getSessionRequest && getSessionRequest.success) {
      cacheUtil.set("get-session", getSessionRequest, 5*60*1000);
      console.log("getSessionRequest.userInfo", getSessionRequest.userInfo);

      let permitPages = false;
      let persona = {};

      // const name = 'Report.DispositionReport'; // You need to set the correct name prop
      let informationLink = await this.fetchInfoLink();
      let teamMembers = await this.GetAllMembersByUserId(getSessionRequest.userInfo.admin_id);

      try {
        getSessionRequest.permissions.map((v) => {
          permitPages[v.page] = true;
        })
        persona = getSessionRequest && getSessionRequest.persona;
      } catch (e) {

      }

      if (getSessionRequest.userInfo && getSessionRequest.userInfo.admin == 'mark@gmail.com') {
        getSessionRequest.userInfo.is_superuser = 1;
      }

      //alert(JSON.stringify(teamMembers))
      localStorage.setItem('teamMembers', JSON.stringify(teamMembers));
      setCookie('teamMembers', teamMembers, 1, true)
      setCookie('confSettings', confSettings, 1, true)

      this.setState({ permitPages: permitPages, user: getSessionRequest.userInfo, persona, informationLink, configSettings: confSettings });
      userInfo = getSessionRequest.userInfo;
      let url = window.location.href;

      //if (userInfo.role == 'owner' && !(url.indexOf('localhost') > -1)) {
      // var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      // s1.async = true;
      // s1.id = 'scChat';
      // s1.src = 'https://embed.tawk.to/5c2c3df682491369baa01f41/default';
      // s1.charset = 'UTF-8';
      // s1.setAttribute('crossorigin', '*');
      // s0.parentNode.insertBefore(s1, s0);
      //}

      if (userInfo.expireon) {
        let expiryDay = window.moment(userInfo.expireon).diff(moment(), 'days');
        if (expiryDay > 0 && expiryDay < 15) {
          this.setState({ expired: true, expireon: window.moment(userInfo.expireon).format('DD/MM/YYYY') });
        }
      }
      // (function () {

      // })();

    }
    else {
      return history.push("/auth/login-page");
    }
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={""}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          user={this.state.user}
          permitPages={this.state.permitPages}
          persona={this.state.persona}
          configSettings={this.state.configSettings}
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Navbar
            routes={routes}
            handleDrawerToggle={this.handleDrawerToggle}
            user={this.state.user}
            permitPages={this.state.permitPages}
            persona={this.state.persona}
            {...rest}
            configSettings={this.state.configSettings}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

          <div className={classes.content}>
            <div className={classes.container}>
              {
                this.state.expired && (
                  <>
                    <div style={{ background: 'orange', padding: "10px", marginLeft: "5px" }}>
                      <br />
                      Your Account will be expired on {this.state.expireon}.
                    </div>
                  </>

                )
              }

              <Switch>
                {routes.map((prop, key) => {
                  if (prop.layout === "/admin") {
                    return (
                      <Route
                        path={prop.layout + prop.path}
                        component={(props) => {
                          const Component = prop.component;

                          const name = prop.name; // Get the name from the route
                          // this.fetchInfoLink(name); // Fetch infoLink based on the name
                          //  const infoLinkUrl = this.state.infoLinkData[name] || ""; // Get the infoLinkUrl from the dictionary

                          return (
                            <Component {...props} persona={this.state.persona} user={this.state.user} configSettings={this.state.configSettings} />
                          );
                        }}
                        key={key}
                      />
                    );
                  }
                })}
              </Switch>
            </div>
          </div>

          {/* Add Footer here */}
          {this.state.configSettings && this.state.configSettings.version && (
            <Footer version={this.state.configSettings.version || "1.0.0"} />
          )}
        
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Dashboard);
