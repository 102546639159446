import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.jsx";
import RefreshIcon from "@material-ui/icons/Refresh";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const Footer = ({ version }) => {
  const handleReload = () => {
    window.location.reload(); // Reload the page to fetch the updated version
  };

  const handleBack = () => {
    window.history.back(); // Navigate to the previous page
  };

  const handleForward = () => {
    window.history.forward(); // Navigate to the next page
  };

  return (
    <footer style={{ textAlign: "center", padding: "10px", background: "#f4f4f4" }}>
      <p>Version: {version}</p>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginTop: "10px" }}>
        <Button
          variant="outlined"
          color="default"
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={handleReload}
        >
          Reload
        </Button>
        <Button
          variant="outlined"
          color="default"
          startIcon={<ArrowForwardIcon />}
          onClick={handleForward}
        >
          Forward
        </Button>
       
      </div>
    </footer>
  );
};

Footer.propTypes = {
  version: PropTypes.string.isRequired,
};

export default Footer;
